<template>
  <div>
    <div class="title">{{ swiper.title }}</div>
    <div class="line"></div>
    <div class="modal-swiper">
      <swiper class="swiper1" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiper.imgList" :key="index">
          <img :src="item.img" />
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <!-- <div class="swiper-pagination pagination" slot="pagination"></div> -->
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
export default {
  components: {},
  props: {
    swiper: Object,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: false, //设置可自动播放
        speed: 1000, //自动播放的速度，每隔1秒滑动一次
        // pagination: {
        //   el: ".swiper-pagination", //分页器的类名
        //   clickable: true, //设置分页小圆点可手动点击
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        effect: "coverflow",
        slidesPerView: 2,
        centeredSlides: true,
        initialSlide: 1,
        coverflowEffect: {
          rotate: 0,
          stretch: 10,
          depth: 160,
          modifier: 2,
          slideShadows: true,
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.title {
  text-align: center;
  color: #000028;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
}
.line {
  width: 58px;
  height: 2px;
  background: #ef8200;
  margin: 0 auto;
  margin-bottom: 100px;
}

.modal-swiper {
  position: relative;
  display: flex;
  justify-content: center;
  .swiper1 {
    width: 1200px;
    // height: 380px;
    // img {
    //   width: 620px;
    //   height: 350px;
    // }
  }

  .pagination {
    position: absolute;
    bottom: -100px;
    display: flex;
    justify-content: space-around;
    /deep/span {
      margin: 10px;
    }
  }
}
</style>