<template>
  <div class="com">
    <div class="title">视频拍摄</div>
    <div class="line"></div>
    <div class="modal-swiper">
      <swiper class="swiper1" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in vList" :key="index">
          <video :src="item.url" controls></video>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
export default {
  components: {},
  props: {},
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: false, //设置可自动播放
        speed: 1000, //自动播放的速度，每隔1秒滑动一次
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        effect: "coverflow",
        slidesPerView: 1,
        centeredSlides: true,
        initialSlide: 1,
        coverflowEffect: {
          rotate: 0,
          stretch: 10,
          depth: 160,
          modifier: 2,
          slideShadows: true,
        },
      },
      vList: [
        {
          url: "https://plus2-img.xizi.com/202104/26/lltgMoa0tbAUooZY9qxjLdwIrR0A.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/llpyUKNSbAcQ1HDrBX_5mkHCyFk0.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/lh-UTV3BQ58TXTgAwpTbgImQaW43.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/lgf1-la-2uCvaXwvWdtYg2GRk1MC.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/lum49x3fOgOi8HsnEqg0iWJYwWTN.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/lmOO-fiqdySG8ikZHYyZ0hQGio8x.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/lsOLNiBK5ont0LgMiNBlmOFoGpE6.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/lvnajF5loZQUdpFFzr2YhR_vyRp3.mp4",
        },
        {
          url: "https://plus2-img.xizi.com/202103/22/loIVirnb4GmZe6_PHjOn3efHzb0C.mp4",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.com {
  height: 660px;
  .title {
    text-align: center;
    color: #000028;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .line {
    width: 58px;
    height: 2px;
    background: #ef8200;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
.modal-swiper {
  position: relative;
  display: flex;
  justify-content: center;
  .swiper1 {
    width: 1000px;
    // height: 450px;
    display: flex;
    video {
      width: 1000px;
    }
  }
}
</style>