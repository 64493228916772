<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/business/img-bg.png" class="img" />
      </div>
    </div>
    <div class="content1">
      <div class="top">
        <div class="title">业务范围</div>
        <div class="line"></div>
        <div class="title1">THE BUSINESS SCOPE</div>
      </div>
      <div class="bom">
        <div class="title">
          <div
            class="item"
            :class="{ active: index == curIndex }"
            v-for="(item, index) in businessList"
            :key="index"
            @click="tabbtn(index)"
          >
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
        <div class="content">
          <div class="box">
            <Carousel v-model="curIndex" loop arrow="never" dots="none">
              <CarouselItem v-for="(item, index) in businessList" :key="index">
                <div class="item-box">
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">优秀案例</div>
        <div class="line"></div>
        <div class="title1">EXCELLENT CASES</div>
      </div>
      <div class="bom">
        <div class="bom-left">
          <div class="item" @click="showBox(-1)">
            <div
              class="item-one"
              @mouseleave="mouseLeave0"
              @mouseover="mouseOver0"
            >
              <div class="item-top">
                <img class="img" src="../../assets/img/business/case0.png" />
                <div v-if="!curIndex0" class="title">活动策划</div>
              </div>
              <div
                v-if="curIndex0"
                class="box animated"
                :class="{ fadeIn: curIndex0 }"
              >
                <div class="box-cen">
                  <img src="../../assets/img/business/case-icon.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bom-right">
          <div
            class="item"
            v-for="(item, index) in caseList"
            :key="item.index"
            @click="showBox(item.index)"
          >
            <div
              class="item-one"
              @mouseleave="mouseLeave"
              @mouseover="mouseOver(item.index)"
            >
              <div class="item-top">
                <img :src="item.img" class="img" />
                <div  v-if="index != curIndex1" class="title">{{ item.title }}</div>
              </div>
              <div
                v-if="index == curIndex1"
                class="box animated"
                :class="{ fadeIn: index == curIndex1 }"
              >
                <div class="box-cen">
                  <img src="../../assets/img/business/case-icon.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mc-box" v-if="showModal">
        <div class="modalBox animated bounceIn">
          <div class="modal-head">
            <div></div>
            <img
              src="../../assets/img/business/icon-del.png"
              class="del"
              @click="del"
            />
          </div>
          <modelSwiper1 v-if="show0" :swiper="swiper0"></modelSwiper1>
          <modelSwiper2 v-if="show3" :swiper="swiper3"></modelSwiper2>
          <modelSwiper1 v-if="show5" :swiper="swiper5"></modelSwiper1>
          <modelVideo v-if="show1"></modelVideo>
          <modelSwiper2 v-if="show2" :swiper="swiper2"></modelSwiper2>
          <modelSwiper2 v-if="show4" :swiper="swiper4"></modelSwiper2>
          <modelSwiper2 v-if="show6" :swiper="swiper6"></modelSwiper2>
        </div>
      </div>
    </div>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
import modelVideo from "../../components/modelVideo.vue";
import modelSwiper1 from "../../components/modelSwiper1.vue";
import modelSwiper2 from "../../components/modelSwiper2.vue";

export default {
  metaInfo: {
    title: "地道益农",
    meta: [
      {
        name: "Business",
        content: "业务与案例",
      },
    ],
  },
  components: { floatBar, modelVideo, modelSwiper1, modelSwiper2 },
  props: {},
  data() {
    return {
      businessList: [
        {
          index: 0,
          title: "政企双微服务",
          img: require("../../assets/img/business/1.png"),
          text: "通过把握正确的政治方向、独特的选题风格、独家的新闻视点，运用文案写作、选题策划、内容编辑等手段帮助政府、企业、事业单位运营出具有新风格、新风向、新优势、新特点的新媒体。可承接微信公众号、微博、今日头条、一点号、网易号、搜狐号、新闻网站、短视频平台（抖音、快手、视频号等）和其他相关内容的代运营。",
        },
        {
          index: 1,
          title: "营销策划与活动执行",
          img: require("../../assets/img/business/2.png"),
          text: "全案策划、落地执行、媒体推广、互动传播、政企活动、创意服务、品牌营销、资源整合，运用贴合实际的策略、生动的创意、可行性的设计、智能全面的技术资源，提供优质时效，可执行的一体化专业服务，坚持创新，精心策划，致力执行。",
        },
        {
          index: 2,
          title: "视频拍摄制作",
          img: require("../../assets/img/business/3.png"),
          text: "用镜头记录发展，用画面讲述故事，讲好中国故事，传播正能量。专注政府、企业、事业单位相关视频需求，擅长短视频、纪录片、宣传片、专题片、微电影等各类型视频创作，相信专业的力量，用影像成就品牌价值。",
        },
        {
          index: 3,
          title: "创意设计",
          img: require("../../assets/img/business/4.png"),
          text: "一个新创意通往一个新设计，一个新设计通往一个新世界，擅长文化创意设计、广告设计、图文设计、企业形象定制、VI全案、手绘漫画、UI交互设计、网页设计等全方位一体化设计服务。",
        },
        {
          index: 4,
          title: "全栈性技术开发",
          img: require("../../assets/img/business/5.png"),
          text: "以服务客户为本，致力于网页设计开发、各类小程序开发、H5开发及H5专项定制服务、营销互动类游戏、答题抽奖等技术开发服务。",
        },
        {
          index: 5,
          title: "招聘服务",
          img: require("../../assets/img/business/6.png"),
          text: "天府职聘网旨在缓解当下就业难、招聘难等问题，为成都本地企业和求职者提供专业的招聘求职服务。平台内行业涵盖广泛，上万岗位选择。天府职聘网拥有专业的服务团队为企业提供专属定制招聘方案、专场直播招聘会以及大型线下专场招聘会等多形式招聘活动的策划与执行；专业求职客服免费为本地求职者提供一对一岗位匹配、岗位体验等，为企业搭建多元化的人才招聘平台，为求职者提供优质的求职渠道，成为本地优质招聘品牌。",
        },
        {
          index: 6,
          title: "MG动画制作",
          img: require("../../assets/img/business/7.png"),
          text: "MG动画，可以理解为动态图形或者图形动画。 简单的来说可以解释为会动的图形设计，是影像艺术的一种。目前我司制作的MG动画能为您解决企业宣传、微课制作、市场营销、企业文化、知识科普等问题，其表现形式丰富多样，打破传统视频束缚，具有极强的包容性、可视性、趣味性。",
        },
      ],
      curIndex: 0,
      caseList: [
        {
          title: "视频拍摄",
          index: 0,
          img: require("../../assets/img/business/case1.png"),
        },
        {
          title: "创意设计",
          index: 1,
          img: require("../../assets/img/business/case2.png"),
        },
        {
          title: "手绘漫画",
          index: 2,
          img: require("../../assets/img/business/case3.png"),
        },
        {
          title: "运营好文",
          index: 3,
          img: require("../../assets/img/business/case4.png"),
        },
        {
          title: "招聘服务",
          index: 4,
          img: require("../../assets/img/business/case5.png"),
        },
        {
          title: "小程序案例",
          index: 5,
          img: require("../../assets/img/business/case6.png"),
        },
      ],
      curIndex1: null,
      curIndex0: false,
      showModal: false,
      // showModal: true,
      show0: false,
      // show0: true,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      swiper0: {
        title: "活动策划",
        imgList: [
          { img: require("../../assets/img/business/swiper/1-01.png") },
          { img: require("../../assets/img/business/swiper/1-02.png") },
          { img: require("../../assets/img/business/swiper/1-03.png") },
          { img: require("../../assets/img/business/swiper/1-04.png") },
          { img: require("../../assets/img/business/swiper/1-05.png") },
          { img: require("../../assets/img/business/swiper/1-06.png") },
          { img: require("../../assets/img/business/swiper/1-07.png") },
          { img: require("../../assets/img/business/swiper/1-08.png") },
          { img: require("../../assets/img/business/swiper/1-09.png") },
          { img: require("../../assets/img/business/swiper/1-10.png") },
          { img: require("../../assets/img/business/swiper/1-11.png") },
          { img: require("../../assets/img/business/swiper/1-12.png") },
          { img: require("../../assets/img/business/swiper/1-13.png") },
          { img: require("../../assets/img/business/swiper/1-14.png") },
          { img: require("../../assets/img/business/swiper/1-15.png") },
          { img: require("../../assets/img/business/swiper/1-16.png") },
          { img: require("../../assets/img/business/swiper/1-17.png") },
          { img: require("../../assets/img/business/swiper/1-18.png") },
          { img: require("../../assets/img/business/swiper/1-19.png") },
          { img: require("../../assets/img/business/swiper/1-20.png") },
        ],
      },
      swiper2: {
        title: "创意设计",
        imgList: [
          { img: require("../../assets/img/business/swiper/3-01.png") },
          { img: require("../../assets/img/business/swiper/3-02.png") },
          { img: require("../../assets/img/business/swiper/3-03.png") },
          { img: require("../../assets/img/business/swiper/3-04.png") },
          { img: require("../../assets/img/business/swiper/3-05.png") },
          { img: require("../../assets/img/business/swiper/3-06.png") },
          { img: require("../../assets/img/business/swiper/3-07.png") },
          { img: require("../../assets/img/business/swiper/3-08.png") },
          { img: require("../../assets/img/business/swiper/3-09.png") },
          { img: require("../../assets/img/business/swiper/3-10.png") },
          { img: require("../../assets/img/business/swiper/3-11.png") },
          { img: require("../../assets/img/business/swiper/3-12.png") },
          { img: require("../../assets/img/business/swiper/3-13.png") },
          { img: require("../../assets/img/business/swiper/3-14.png") },
          { img: require("../../assets/img/business/swiper/3-15.png") },
          { img: require("../../assets/img/business/swiper/3-16.png") },
          { img: require("../../assets/img/business/swiper/3-17.png") },
          { img: require("../../assets/img/business/swiper/3-18.png") },
          { img: require("../../assets/img/business/swiper/3-19.png") },
          { img: require("../../assets/img/business/swiper/3-20.png") },
        ],
      },
      swiper3: {
        title: "手绘漫画",
        imgList: [
          { img: require("../../assets/img/business/swiper/4-06.png") },
          { img: require("../../assets/img/business/swiper/4-07.png") },
          { img: require("../../assets/img/business/swiper/4-08.png") },
          { img: require("../../assets/img/business/swiper/4-09.png") },
          { img: require("../../assets/img/business/swiper/4-10.png") },
          { img: require("../../assets/img/business/swiper/4-11.png") },
          { img: require("../../assets/img/business/swiper/4-12.png") },
          { img: require("../../assets/img/business/swiper/4-13.png") },
          { img: require("../../assets/img/business/swiper/4-14.png") },
          { img: require("../../assets/img/business/swiper/4-15.png") },
          { img: require("../../assets/img/business/swiper/4-16.png") },
          { img: require("../../assets/img/business/swiper/4-17.png") },
          { img: require("../../assets/img/business/swiper/4-18.png") },
          { img: require("../../assets/img/business/swiper/4-19.png") },
          { img: require("../../assets/img/business/swiper/4-20.png") },
        ],
      },
      swiper4: {
        title: "运营好文",
        imgList: [
          { img: require("../../assets/img/business/swiper/5-01.png") },
          { img: require("../../assets/img/business/swiper/5-02.png") },
          { img: require("../../assets/img/business/swiper/5-03.png") },
          { img: require("../../assets/img/business/swiper/5-04.png") },
          { img: require("../../assets/img/business/swiper/5-05.png") },
          { img: require("../../assets/img/business/swiper/5-06.png") },
          { img: require("../../assets/img/business/swiper/5-07.png") },
        ],
      },
      swiper5: {
        title: "招聘服务",
        imgList: [
          { img: require("../../assets/img/business/swiper/6-1.png") },
          { img: require("../../assets/img/business/swiper/6-2.png") },
          { img: require("../../assets/img/business/swiper/6-3.png") },
          { img: require("../../assets/img/business/swiper/6-4.png") },
          { img: require("../../assets/img/business/swiper/6-5.png") },
        ],
      },
      swiper6: {
        title: "小程序案例",
        imgList: [
          { img: require("../../assets/img/business/swiper/7-1.png") },
          { img: require("../../assets/img/business/swiper/7-2.png") },
          { img: require("../../assets/img/business/swiper/7-3.png") },
          { img: require("../../assets/img/business/swiper/7-4.png") },
          { img: require("../../assets/img/business/swiper/7-5.png") },
          { img: require("../../assets/img/business/swiper/7-6.png") },
        ],
      },
    };
  },
  watch: {},
  methods: {
    tabbtn(i) {
      this.curIndex = i;
    },
    mouseOver(i) {
      this.curIndex1 = i;
    },
    mouseLeave() {
      this.curIndex1 = null;
    },
    mouseOver0() {
      this.curIndex0 = true;
    },
    mouseLeave0() {
      this.curIndex0 = false;
    },
    showBox(i) {
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;
      this.show6 = false;
      if (i == -1) {
        this.show0 = true;
      } else if (i == 0) {
        this.show1 = true;
      } else if (i == 1) {
        this.show2 = true;
      } else if (i == 2) {
        this.show3 = true;
      } else if (i == 3) {
        this.show4 = true;
      } else if (i == 4) {
        this.show5 = true;
      } else if (i == 5) {
        this.show6 = true;
      }
      this.showModal = true;
    },
    del() {
      this.showModal = false;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {
    this.toTop();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.top {
  text-align: center;
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.content1 {
  width: 100%;
  padding-top: 70px;
  .bom {
    margin-top: 60px;
    .title {
      display: flex;
      justify-content: space-around;
      margin-left: 160px;
      margin-right: 160px;
      margin-bottom: 33px;
      .item {
        width: 180px;
        background: #f4f4f4;
        height: 48px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        border-radius: 24px;
        color: #6f6f6f;
        font-size: 16px;
      }
      .active {
        background: #ef8200;
        .text {
          color: #fff;
        }
      }
    }
    .content {
      width: 100%;
      height: 500px;
      // background-color: #ef8200;
      background-image: url("../../assets/img/business/content-img.png");
      background-size: 100%;
      background-repeat: no-repeat;
      .box {
        width: 980px;
        height: 500px;
        margin: 0 auto;
        // background: #ef8200;
        .item-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .img {
            width: 120px;
            height: 120px;
          }
          .text {
            font-size: 16px;
            color: #fff;
            display: flex;
            align-items: center;
            width: 630px;
            height: 500px;
            text-align: justify;
          }
        }
      }
    }
  }
}
.content2 {
  padding-top: 70px;
  .bom {
    width: 1427px;
    height: 600px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 90px;
    display: flex;
    .bom-left {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 551px;
        height: 600px;
        cursor: pointer;
        .item-one {
          position: relative;
          width: 551px;
          height: 600px;
          .item-top {
            .img {
              width: 551px;
              height: 600px;
            }
            .title {
              width: 551px;
              height: 46px;
              line-height: 46px;
              background-color: rgba(0, 0, 0, 0.5);
              text-align: center;
              color: #fff;
              font-size: 16px;
              position: absolute;
              bottom: 0;
            }
          }
          .box {
            position: absolute;
            z-index: 99;
            top: 0;
            width: 551px;
            height: 600px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            .box-cen {
              width: 118px;
              height: 118px;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }
    .bom-right {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 292px;
        height: 300px;
        cursor: pointer;
        .item-one {
          position: relative;
          width: 292px;
          height: 300px;
          .item-top {
            .img {
              width: 292px;
              height: 300px;
            }
            .title {
              width: 292px;
              height: 46px;
              line-height: 46px;
              background-color: rgba(0, 0, 0, 0.5);
              text-align: center;
              color: #fff;
              font-size: 16px;
              position: absolute;
              bottom: 0;
            }
          }
          .box {
            position: absolute;
            z-index: 99;
            top: 0;
            width: 292px;
            height: 300px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            .box-cen {
              width: 88px;
              height: 88px;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }
    }
  }
  .mc-box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    .modalBox {
      padding: 20px;
      width: 1400px;
      height: 700px;
      background: #fff;
      border-radius: 10px;
      position: fixed;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .modal-head {
        display: flex;
        justify-content: space-between;
        .del {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
